<template>
  <div class="apply-list-query">
    <div
      v-for="(item, idx) in configs"
      :key="idx"
      class="apply-list-query__item"
      @click="onSearchClick(item, idx)"
    >
      <span class="apply-list-query__item-title">{{ item.title }}</span>
      <van-icon
        name="zhankai"
        class-prefix="iconfont"
        class="apply-list-query__item-icon"
      />
    </div>
    <div
      :class="['danger-radio', { active: queryInfo.involveDanger === 1 }]"
      @click="onDangerClick"
    >
      涉及重大危险源
    </div>
    <select-popup
      :choose-value="popupValue"
      :show-filter="false"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '50vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
    <cascade-department
      v-model="queryInfo.department"
      :visible="departmentVisible"
      title="部门"
      include-children
      @close="departmentVisible = false"
      @confirm="onDepartmentConfirm"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
import { getApplyTypeSelect, getApplyFlowStateSelect } from "../api";

export default {
  name: "ApplyQuery",
  components: { SelectPopup },
  data() {
    return {
      queryInfo: {
        // 变更类型
        changeTypeId: "",
        // 部门
        department: "",
        // 流转状态
        flowState: "",
        // 是否涉及重大危险源 0：不涉及 1：涉及
        involveDanger: 0
      },
      departmentVisible: false,
      configs: [
        {
          title: "变更类型",
          type: "changeTypeId",
          list: [],
          value: ""
        },
        {
          title: "部门",
          type: "department",
          value: ""
        },
        {
          title: "流转状态",
          type: "flowState",
          list: [],
          value: ""
        }
      ],
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      selectValue: "",
      popupValue: "",
      popupType: ""
    };
  },
  created() {
    this.initTypes();
  },
  watch: {
    "queryInfo.changeTypeId": {
      handler(val) {
        if (val) {
          getApplyFlowStateSelect(val).then(res => {
            if (res) {
              res = res.map(item => ({
                label: item,
                value: item
              }));
              this.configs[2].list = res;
            }
          });
        } else {
          this.configs[2].list = [];
        }
      }
    }
  },
  methods: {
    onDangerClick() {
      this.queryInfo.involveDanger = this.queryInfo.involveDanger ? 0 : 1;
      this.refreshQuery();
    },
    onDepartmentConfirm(data) {
      this.queryInfo.department = data?.id || "";
      this.refreshQuery();
    },
    refreshQuery() {
      this.$emit("refresh", this.queryInfo);
    },
    async initTypes() {
      try {
        let res = await getApplyTypeSelect({
          orgCode: this.userInfo.orgCode,
          sort: "utime_desc",
          privilegeType: "1",
          needMenu: 1
        });
        res = res.map(item => ({
          label: item.name,
          value: item.id
        }));
        this.configs[0].list = res;
      } catch (error) {
        console.log("initTypes -> error", error);
      }
    },
    onSearchClick({ title, type, list }, idx) {
      if (type === "department") {
        this.departmentVisible = true;
        return;
      }
      this.popupValue = this.queryInfo[type] || "";
      this.popupType = type;
      this.popupData = list || [];
      this.popupTitle = title;
      this.popupVisible = true;
    },
    onPopupReset() {
      this.queryInfo[this.popupType] = "";
      this.popupValue = "";
      this.refreshQuery();
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupConfirm(row) {
      this.queryInfo[this.popupType] = row.value;
      this.refreshQuery();
    }
  }
};
</script>

<style lang="scss">
.apply-list-query {
  display: flex;
  align-items: center;
  padding: 2px 18px 12px;
  margin-top: -2px;
  color: #3b4664;
  font-size: 12px;
  border-bottom: 1px solid #c7c9d0;
  &__item {
    margin-right: 8px;
    background: #eaeff5;
    border-radius: 10px;
    padding: 4px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-icon {
      margin-left: 4px;
      color: #aeb3c0;
      font-size: 12px;
    }
  }
  .danger-radio {
    font-size: 11px;
    color: #f55f5f;
    margin-left: auto;
    background: #f55f5f15;
    border-radius: 2px;
    padding: 3px 5px;
    &.active {
      color: #fff;
      background: #f55f5f;
    }
  }
}
</style>
