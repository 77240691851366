<template>
  <div class="apply-list">
    <van-nav-bar left-arrow title="变更作业票" @click-left="$router.back()">
      <template #right>
        <span @click="onQueryTypeClick">{{
          queryInfo._queryAll ? "全部" : "与我相关"
        }}</span>
      </template>
    </van-nav-bar>
    <div class="search-query">
      <van-search
        v-model="queryInfo.name"
        class="search-bar"
        placeholder="请输入变更名称关键字"
        @clear="onRefresh"
        @search="onRefresh"
      >
        <template #left-icon>
          <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
        </template>
      </van-search>
      <date-time
        v-model="dateTime"
        type="datetime"
        clearable
        format="{y}-{m}-{d} {h}:{i}"
      />
      <apply-query :query="queryInfo" @refresh="onQueryChange" />
      <div class="apply-total">
        <template v-if="!total">没有符合条件的数据</template>
        <template v-else>共{{ total }}个结果</template>
      </div>
    </div>
    <van-pull-refresh
      v-model="isPullRefreshing"
      class="apply-list__wrapper"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <van-cell
          v-for="(item, idx) in list"
          :key="idx"
          :class="[
            'apply-list__rows',
            idx === list.length - 1 && 'border-none'
          ]"
          :border="false"
          @click="gotoDetails(item)"
        >
          <div v-if="idx !== 0" class="line-block" />
          <div class="content">
            <h3 class="title">
              {{ item.name }}
            </h3>
            <p>{{ item.code }}</p>
            <div class="info">
              <span
                ><i class="iconfont iconfont-guankongcengji"></i
                >{{ item.changeTypeName }}</span
              >
              <span
                ><i class="iconfont iconfont-bumen"></i
                >{{ item.departmentName }}</span
              >
            </div>
            <div class="datetime">
              <span
                ><i class="iconfont iconfont-riqi"></i>{{ item.utime }}</span
              >
            </div>
            <div v-if="item.involveDanger" class="is-danger">危险源</div>
            <div class="flow-state" :style="computedStyle(item)">
              {{ item.flowState }}
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
    <fixed-line />
  </div>
</template>

<script>
import ApplyQuery from "./components/ApplyQuery";
import dayjs from "dayjs";
import { getPageChanges } from "./api";
import { mapState } from "vuex";
import FixedLine from "./components/FixedLine";
export default {
  name: "ApplyList",
  components: { ApplyQuery, FixedLine },
  data() {
    return {
      queryInfo: {
        name: "",
        orgCode: "",
        startTime: "",
        endTime: "",
        page: 1,
        size: 10,
        sort: "ctime_desc",
        needMenu: 1,
        _queryAll: false // 与我相关 全部
      },
      dateTime: { start: "", end: "" },
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false,
      urls: []
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    computedStyle() {
      return item => {
        const { flowState } = item;
        const style = {};
        if (flowState === "已完成") {
          style.color = "#8eb433";
        } else if (flowState === "终止") {
          style.color = "#9b9b9b";
        } else {
          style.color = "#5a96e0";
        }
        return style;
      };
    }
  },
  watch: {
    dateTime: {
      handler(val) {
        if (val.start && val.end) {
          this.queryInfo.startTime = this.formatDate(val.start);
          this.queryInfo.endTime = this.formatDate(val.end);
          this.onRefresh();
        }
        if (!val.start && !val.end) {
          this.queryInfo.startTime = "";
          this.queryInfo.endTime = "";
          this.onRefresh();
        }
      },
      deep: true
    }
  },
  activated() {
    this.onRefresh();
  },
  methods: {
    onQueryTypeClick() {
      this.queryInfo._queryAll = !this.queryInfo._queryAll;
      this.onRefresh();
    },
    onQueryChange(query) {
      this.queryInfo = { ...this.queryInfo, ...query };
      this.onRefresh();
    },
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    },
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        const data = await getPageChanges(this.queryInfo);
        const { list, total } = data || { list: [], total: 0 };
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    gotoDetails({ id }) {
      this.$router.push({
        name: "applyDetail",
        // params: { id: "451252354142961664" }
        params: { id }
      });
    }
  }
};
</script>

<style lang="scss">
.apply-list {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .search-bar {
    padding-bottom: 0;
  }
  .apply-total {
    text-align: center;
    color: #8c8f97;
    font-size: 14px;
    padding: 14px 0;
  }
  &__wrapper {
    overflow-y: auto;
  }
  &__rows {
    padding: 0;
    .van-cell__value {
      .content {
        position: relative;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 10px;
        font-size: 12px;
        color: #8c8f97;
        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 20px;
          font-size: 14px;
          color: #3b4664;
          font-weight: 500;
          margin-top: 10px;
        }
        .info,
        .datetime {
          > span {
            margin-right: 22px;
            i {
              color: #aeb3c0;
              margin-right: 5px;
            }
          }
        }
        .is-danger {
          position: absolute;
          top: -10px;
          right: 0;
          padding: 0 4px;
          background: #f55f5f15;
          color: #f55f5f;
          &::before {
            content: "";
            position: absolute;
            left: -12px;
            border-left: 6px solid transparent;
            border-top: 12px solid #f55f5f15;
            border-right: 6px solid #f55f5f15;
            border-bottom: 12px solid transparent;
          }
        }
        .flow-state {
          position: absolute;
          top: calc(50% - 12px);
          right: 20px;
          font-weight: 500;
        }
      }
    }
    .line-block {
      margin: 0 -16px;
    }
  }
}
</style>
