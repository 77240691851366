<template>
  <div class="fixed-line"></div>
</template>

<script>
export default {
  name: "FixedLine"
};
</script>

<style lang="scss" scoped>
.fixed-line {
  position: fixed;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  width: 134px;
  height: 5px;
  background: #000000;
  border-radius: 100px;
}
</style>
