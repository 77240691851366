import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";

const { psm, workflow } = apiPath;

// 获取变更管理列表
export const getPageChanges = params => {
  params = JSON.parse(JSON.stringify(params));
  const queryAll = params._queryAll;
  delete params._queryAll;
  if (queryAll) {
    return axios.get(`${psm}/page/changes`, { params });
  }
  return axios.get(`${psm}/page/changes/mine`, { params });
};
// 获取变更管理详情
export const getPageChangesDetail = params => {
  return axios.get(`${psm}/changes/${params.id}`, { params });
};
// 获取变更类型下拉
export const getApplyTypeSelect = params => {
  return axios.get(`${psm}/list/change-types`, { params });
};
// 获取变更类型流转状态
export const getApplyFlowStateSelect = id => {
  return axios.get(`${psm}/change-types/${id}/status`);
};
// 获取工作进度
export const getValues = id => {
  return axios.get(`${psm}/list/values?changeId=${id}`);
};
/**
 * 转派
 * @param data
 * @returns {*}
 */
export const postChangeTransfers = function(data) {
  return axios.post(`${psm}/change/transfers`, null, { params: data });
};

/**
 * 获取定义工作流节点表单
 * @param params
 * @returns {*}
 */
export const getDefinitionFlowForms = params => {
  return axios.get(`${workflow}/definition/flow-forms`, { params });
};

// 保存自定义表单
export const saveValues = data => {
  return axios.post(`${psm}/commit/values`, data);
};
